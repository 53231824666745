window.addEventListener('load', ()=>{
    changeActive();
});

const block = document.querySelector('.сases-block');

function changeActive() {
    const navItem = block.querySelectorAll('.cases-nav .nav-item');

    if (navItem.length > 1) {
        const items = block.querySelectorAll('.cases-inner .case-wrapper');
        const popupImage = block.querySelector('.popup .img-wrapper img');

        navItem.forEach((button, id) => {
            button.addEventListener('click', ()=>{
                navItem.forEach(element => {
                    element.classList.remove('active');
                });

                items.forEach(element => {
                    element.classList.remove("active");
                });
                items[id].classList.add('active');
                button.classList.add('active');
            });
        });

        items.forEach(item => {
            const button = item.querySelector('.img-wrapper');
            const image = button.querySelector('img');

            if (button) {
                button.addEventListener('click', ()=>{
                    popupImage.setAttribute("src", image.src);
                    popupImage.setAttribute('alt', image.alt);
                });
            }
        });
    }
}
